.Hangman {
    width: 60%;
    height: 65%;
    /*text-align: center;*/
    display: flex;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    position: relative;
  }

.Hangman h1 {
  font-size: 4rem;
  font-weight: 100;
  margin-bottom: 0.1em;
  margin-top: 0;
}

.Hangman-title {
  margin-top: 5%;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline;
}

.Hangman-hint {
  font-size: 1.7rem;
  margin-right: 5%;
}

.Hangman-word {
  letter-spacing: 1em;
  margin-right: 3%;
  font-size: 2.5rem;
}
  
.Hangman-btns {
  text-align: center;
  display: inline-block;
  /*width: 250px;*/
  width: 90%;
  margin-top: 5%;
  margin-right: 3%;
  margin-bottom: 10%;
}
  
.Hangman button {
  border: none;
  font-family: inherit;
  font-size: 2rem;
  cursor: pointer;
  width: 38px;
  margin: 8px 5px 5px 5px;
  letter-spacing: 1px;
  outline: none;
  background: #04e604;
  color: #000000;
  box-shadow: 0 6px #028113;
  border-radius: 5px;
  padding-top: 4px;
}
  
#reset {
  width: 60px;
  background-color: green;
}
  
.Hangman button:hover {
  background-color: #b9fabe;
}
  
.Hangman button:disabled {
  background: #bdbdbd;
  color: #eeeeee;
  box-shadow: 0 6px #9e9e9e;
}

.image-place {
  width: 40%;
  float: left;
  /*padding: 2%;*/
  overflow: auto;
  /*position: absolute;
  top: 25%;*/
  position: relative;
}  

.game-place {
  width: 60%;
  float: right;
  padding: 2%;
  overflow: auto;
  /*position: absolute;
  top: 25%;*/
}  

#main-image {
  margin: 0;
  position: absolute;
  top: 47%;
  left: 50%;
  /*-ms-transform: translate(-50%, -50%);*/
  transform: translate(-50%, -50%); 
  max-width: 85%;
}

img {
  border-radius: 15%;
  max-width: 100%;
  /*width: 100%;*/
  height: auto;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 2%;
  width: 100%;
  background-color: rgb(200, 243, 200);
  color: white;
  text-align: center;
  font-size: 1.5rem;
}

a:link {
  color: rgb(93, 233, 93);
}

a:visited {
  color: rgb(30, 179, 30);
}
