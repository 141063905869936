.Modal-image {
  width: 100%;
  height: auto;
  margin-top: 10%;
  margin-bottom: 2%;
} 

.Modal-text {
  margin-bottom: 6%;
  font-size: 1.6rem;
} 

#btt-modal {
  background: #0de743;
  color: #ffffff;
  box-shadow: 0 6px #a5e2aa;
  border-radius: 5px;
  width: 50%;
  margin-top: 10%;
  margin-bottom: 10%;
  height: 10%;
}

#btt-modal:hover {
  background-color: #d5d817;
}

.Modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0,0,0, 0.7);
  z-Index: 1000;
} 

.Modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 4%;
  background-color: rgb(255, 255, 255);
  border-Radius: 10px;
  color: black;
  width: 25%;
  height: 65%;
} 
