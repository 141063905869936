@media screen and (max-width: 1650px) {
  .Hangman {
    width: 70%;
  }

  .Modal-style {
    width: 35%;
    padding: 8%;
  }
}


@media screen and (max-width: 1500px) {
  .Hangman {
    width: 75%;
  }

  .Hangman-word {
    letter-spacing: 0.8em;
    margin-right: 2%;
  }

  .Modal-style {
    width: 40%;
  }
}


@media screen and (max-width: 1300px) {
  .Hangman {
    width: 80%;
    height: 60%;
  }

  .Hangman-title {
    font-size: 1.8rem;
  }
  
  .Hangman-hint {
    font-size: 1.5rem;
  }
  
  .Hangman-word {
    letter-spacing: 0.7em;
    margin-right: 1%;
    font-size: 2.3rem;
  }

  .Hangman-btns {
    width: 100%;
  }
}


@media screen and (max-width: 1100px) {
  .Hangman {
    width: 90%;
  }

  .Hangman-title {
    font-size: 1.7rem;
  }

  .Hangman-hint {
    font-size: 1.4rem;
  }
  
  .Hangman-word {
    letter-spacing: 0.6em;
    margin-right: 1%;
    font-size: 2.1rem;
  }

  .Hangman-btns {
    width: 100%;
  }
}


@media screen and (max-width: 800px) {
  .Hangman {
    width: 95%;
  }

  .Hangman-word {
    letter-spacing: 0.2em;
    margin-right: 0.5%;
  }

  .Modal-style {
    width: 60%;
  }
}


@media screen and (max-width: 550px) {
  .Hangman-word {
    letter-spacing: 1.2em;
    margin-right: 3%;
    font-size: 2rem;
  }
  
  .Hangman {
    width: 100%;
    height: 100%;
    display: block;
  }
  
  .image-place {
    width: 100%;
    float: none;
    height: 40%;
  }  
  
  .game-place {
    width: 100%;
    float: none;
    height: 60%;
  }

  .Modal-style {
    width: 85%;
    padding: 18%;
  }

  #btt-modal {
    width: 70%;
    margin-bottom: 50%;
  }

  .footer {
    bottom: 0;
    background-color: rgb(255, 255, 255);
    font-size: 1.2rem;
  }

}
